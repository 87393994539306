<template>
    <div class="container"> 
         <div class="content">
          <div class="content-left">
            <router-view></router-view> 
          </div>
          <div class="content-right">
            <recommend-list></recommend-list>
          </div>
         </div>
         <content-footer></content-footer>
    </div>
  </template>
  
  <script>
  import RecommendList from './components/recommend-list.vue';
  import ContentFooter from '../home/component/content-footer.vue';
  import {mapMutations} from 'vuex';
  export default {
    name:'GamesNews',
    components:{
        RecommendList,
        ContentFooter,
    },
    methods:{
        ...mapMutations('gameNews',['removeCurrentNewsItemObject'])
    },
    beforeDestroy(){
        this.removeCurrentNewsItemObject();
    }
  }
  </script>
  
  <style lang="less" scoped>
  .container{
      background:#F5F5F5;
      width: calc(100vw);
      min-width: 1440px;
      .content{
          margin: 0 auto;
          width: 1200px;
          display: flex;
          justify-content: space-between;
          .content-left{
            margin-top: 20px;
            width: 860px;
            min-height: 890px;
            background-color: #FFFFFF;
            border-radius: 6px;
          }
          .content-right{
            margin-top: 20px;
            border-radius: 6px;
            width:320px;
            background: #FFFFFF;
            height: calc(115vh);
          }
      }
  }

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #F02233;   // 进行修改选中项背景和字体
    color: #fff;
}
/* 当鼠标划过页码时改变颜色 */
/deep/.el-pagination.is-background .el-pager li:hover {
    color:#F02233 ; /* 你想要设置的颜色 默认#409EFF */
}
  
  </style>