<template>
  <div class="recommend-list-content">
    <p class="article-main-title">推荐文章</p>
    <ul>
        <li v-for="(item,index) in recommendList" :key="index" @click="clickNewsRecommendListItemHandler(item)">
            <img class="recommend-article-pic" :src="item.mainPic" alt="文章主图" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">{{ item.title }}</p>
                <p class="recommend-article-time">{{ item.updateTime }}</p>
            </div>
        </li>
    </ul>
  </div>
</template>

<script>
import {getNewsRecommendList} from '@/services/gameNews';
import {mapState,mapMutations} from 'vuex';
export default {
    data(){
        return {
            recommendList:[]
        }
    },
    methods:{
        ...mapMutations('gameNews',['setCurrentNewsItemObject']),
        //获取推荐列表
        async loadRecommendList(){
            try{
                const {data} = await getNewsRecommendList();
                if(data.code===200){
                    this.recommendList = data.data;
                }
            }catch(err){}
        },
        //点击新闻列表项操作
        clickNewsRecommendListItemHandler(item){
            //如果是文章详情页，就不用跳转了，直接传数据就行了
            if(this.$router.currentRoute.path==='/gamesNews/newsDetails'){
                this.setCurrentNewsItemObject(item);
                return;
            }
            //如果不是详情页，通过路由跳转到详情页，同时传数据
            this.$router.push({name:'newsDetails',params:{articleObject:item}})
        }
    },
    mounted(){
        this.loadRecommendList();
    }
}
</script>

<style lang="less" scoped>
.recommend-list-content{
    width:320px;
    padding: 0 14px;
    .article-main-title{
        margin-top: 12px;
        height: 20px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 15px;
        color: #222222;
        line-height: 20px;
        text-align: left;
        font-style: normal;
    }
    ul{
        width:292px;
        list-style-type: none;
        li{
            margin-top: 30px;
            list-style: none;
            display: flex;
            cursor: default;
            .recommend-article-pic{
                width: 100px;
                height: 56px;
                background: #DEDEDE;
                border-radius: 4px;
            }
            .recommend-article-info{
                margin-left: 10px;
                width:182px;
                display: flex;
                flex-direction: column;
                .recommend-article-title{
                    height: 34px;
                    font-family: MicrosoftYaHei;
                    font-size: 13px;
                    color: #131313;
                    line-height: 17px;
                    text-align: justify;
                    font-style: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp:2;
                    word-break: break-all;
                }
                .recommend-article-time{
                    margin-top: 6px;
                    height: 16px;
                    font-family: MicrosoftYaHei;
                    font-size: 12px;
                    color: #999999;
                    line-height: 16px;
                    text-align: left;
                    font-style: normal;
                }
            }
            &:first-child{
                margin-top: 20px;
            }
            &:last-child{
                margin-bottom: 20px;
            }
            &:hover .recommend-article-title{
                color: #F02233;
            }
        }
    }
}
</style>