import request from "../../utils/request";

//获取文章列表
export const getNewsList = (data)=>{
    return  request({
        method:'POST',
        url:'/game/getNewsList',
        data
    })
}

//获取文章推荐列表
export const getNewsRecommendList = (data)=>{
    return  request({
        method:'GET',
        url:'/game/getNewsRecommendList',
        data
    })
}