<template>
  <div class="news-list-content-contianer">
    <div class="content-top-menu"> 
        <ul>
            <li @click="clickMenuHndler(index)" v-for="(item,index) in menuData" :key="index">
                <p class="menu-title" :style="{color:currentMenuIndex===index?'#F02233':''}">{{item}}</p>
            </li>
            <div class="menu-silder" :style="{width:menuSliderWidthStyle,left:menuSliderLeftStyle}"></div>
        </ul>
    </div>
    <div class="content-bottom-list">
        <news-list-item  :newsList="newsList"></news-list-item>
    </div>
    <div class="content-bottom-pagination" v-if="newsList&&newsList.length>0">
        <div class="block">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="requstObject.pageNumber"
            :page-size="requstObject.pageSize"
            layout="total, prev, pager, next"
            :total="totalCount">
            </el-pagination>
        </div>
    </div>
    <no-product-page v-else :boxWidth="860" title="暂无文章"></no-product-page>
  </div>
</template>

<script>
  import NewsListItem from './news-list-item.vue';
  import {getNewsList} from '@/services/gameNews';
  import NoProductPage from '@/views/components/no-product-page.vue';
export default {
    components:{
        NewsListItem,
        NoProductPage
    },
    data(){
        return{
            menuData:["全部","手游攻略","端游攻略","行业资讯"],
            currentMenuIndex:0, //当前菜单索引
            requstObject:{
                pageNumber:1,//当前页
                pageSize:5 //一页显示的数目
            },
            totalCount:1, //总条数
            isLastPage:false,
            newsList:[
                // {
                //     id:1,
                //     categoryName:'手游攻略',
                //     mainPic:'https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3196bb3d1f5842e7b530d8ac1b1adb60_mergeImage.png',
                //     title:'绝区零哪个角色更强 绝区零强度排行榜单揭秘',
                //     briefContent:'在动作游戏《绝区零》中，不同角色的强度和作用各有侧重，因此选择合适的角色组合至关重要，直接决定了战局的胜负。那么绝区零哪个角色更强？以下是一份绝区零角色强度排行榜单的详细揭秘，帮助玩家更好地理解和选择角色。在《三国谋定天.以下是一份绝区零角色强度排行榜单的详细揭秘，帮助玩',
                //     content:'在动作游戏《绝区零》中，不同角色的强度和作用各有侧重，因此选择合适的角色组合至关重要，直接决定了战局的胜负。那么绝区零哪个角色更强？以下是一份绝区零角色强度排行榜单的详细揭秘，帮助玩家更好地理解和选择角色。在《三国谋定天',
                //     pageView:33,
                //     author:'李舒',
                //     createTime:'2024-07-18 08:30:00',
                //     updateTime:'2024-07-18 08:30:00'
                // }
            ],
            // tempNewsList:[]
        }
    },
    methods:{
        clickMenuHndler(index){
            //console.log('index==='+index);
            this.currentMenuIndex = index;
            //重新加载
            this.requstObject.pageNumber = 1;
            this.requstObject.categoryId = index;
            this.loadGameNewsList();
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.requstObject.pageNumber = val;
            this.loadGameNewsList();
        },
        //加载游戏资讯
        async loadGameNewsList(){
            try{
                const {data} = await getNewsList(this.requstObject);
                if(data.code==200){
                    this.newsList = data.data.dataList;
                    this.totalCount = data.data.totalCount;
                    this.isLastPage = data.data.isLastPage;
                    if(this.isLastPage){ //如果是最后一页，页面滚回到顶部
                        window.scrollTo(0,0);
                    }
                }else{
                    this.$message.warning('获取资讯列表失败')
                }
            }catch(err){}
        },
    },
    mounted(){
        this.requstObject.categoryId = 0;
        this.loadGameNewsList();
    },
    computed:{
        menuSliderWidthStyle(){
            return this.menuData[this.currentMenuIndex].length*15+'px';
        },
        menuSliderLeftStyle(){
            let tempLength = 0;
            this.menuData.forEach((item,index)=>{
                if(index<this.currentMenuIndex){
                    tempLength += this.menuData[index].length*15;
                }
            });
            let ttempLength = this.currentMenuIndex===0?0:tempLength;
            return this.currentMenuIndex*50 + ttempLength + 30 +'px';
        }
    }
}
</script>

<style lang="less">
.news-list-content-contianer{
    width: 100%;
    .content-top-menu{
                width:860px;
                height: 52px;
                background: #FFFFFF;
                box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
                border-radius: 6px 6px 0px 0px;
                ul{
                    height: 52px;
                    width:860px;
                    padding: 20px 20px 20px 30px;
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    position: relative;
                    li{
                        margin-right: 50px;
                        height: 52px;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .menu-title{
                            height: 20px;
                            font-family: MicrosoftYaHei;
                            font-size: 15px;
                            color: #555555;
                            line-height: 20px;
                            text-align: left;
                            font-style: normal;
                            cursor: pointer;
                        }
                        &:hover .menu-title{
                            color: #F02233;
                        }
                    }
                    .menu-silder{
                        position:absolute;
                        left: 0px;
                        bottom: 0px;
                        width: 30px;
                        height: 2px;
                        background: #F02233;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
            .content-bottom-list{
                width: 820px;
                padding: 0px 20px;
                
            }
            .content-bottom-pagination{
                width: 820px;
                height: 68px;
                margin-top: 20px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

            }
}
</style>