<template>
  <div class="news-list-item-content">
    <ul>
        <li v-for="(item,index) in newsList" :key="index" @click="clickNewsListItemHandler(item)">
            <img class="news-list-title-pic" alt="商品主图" :src="item.mainPic">
            <div class="news-list-title-right">
                <p class="news-title-text">{{ item.title }}</p>
                <div class="news-time-and-page-views">
                    <p class="news-time-text">{{ item.createTime }}</p>
                    <img src="/images/home/page_views.png" alt="文章浏览量" class="page-views-icon">
                    <p class="news-page-views">{{ item.pageView }}</p>
                </div>
                <p class="news-brief-introduction">{{ item.briefContent }}</p>
            </div>
        </li>
        <!-- <li>
            <img class="news-list-title-pic" alt="新闻标题图片" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/073caffe39e7458e83322efbe2c6e9d7_mergeImage.png"  >
            <div class="news-list-title-right">
                <p class="news-title-text">《三国谋定天下》哪个职业适合开荒？</p>
                <div class="news-time-and-page-views">
                    <p class="news-time-text">2024-07-18 08:30:00</p>
                    <img src="../../../../public/images/home/page_views.png" alt="文章浏览量" class="page-views-icon">
                    <p class="news-page-views">927</p>
                </div>
                <p class="news-brief-introduction">选择一个适合开荒的职业是每位玩家在游戏初期都需要做的决策。不同的职业会影响到你的资源获取速度、队伍发展以及后期战力的强弱。本文就来给大家说说《三国谋定天下》哪个职业适合开荒？</p>
            </div>
        </li>
        <li>
            <img class="news-list-title-pic" alt="新闻标题图片" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a8ec6a42876d4bf680f7d0ef5d2522f2_mergeImage.png"  >
            <div class="news-list-title-right">
                <p class="news-title-text">绝区零哪个角色更强 绝区零强度排行榜单揭秘</p>
                <div class="news-time-and-page-views">
                    <p class="news-time-text">2024-07-18 08:30:00</p>
                    <img src="../../../../public/images/home/page_views.png" alt="文章浏览量" class="page-views-icon">
                    <p class="news-page-views">927</p>
                </div>
                <p class="news-brief-introduction">在动作游戏《绝区零》中，不同角色的强度和作用各有侧重，因此选择合适的角色组合至关重要，直接决定了战局的胜负。那么绝区零哪个角色更强？以下是一份绝区零角色强度排行榜单的详细揭秘，帮助玩家更好地理解和选择角色。在《三国谋定天...</p>
            </div>
        </li>
        <li>
            <img class="news-list-title-pic" alt="新闻标题图片" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ef901fca803e40bbb96399c2969a30e0_mergeImage.png"  >
            <div class="news-list-title-right">
                <p class="news-title-text">《三国谋定天下》哪个职业适合开荒？</p>
                <div class="news-time-and-page-views">
                    <p class="news-time-text">2024-07-18 08:30:00</p>
                    <img src="../../../../public/images/home/page_views.png" alt="文章浏览量" class="page-views-icon">
                    <p class="news-page-views">927</p>
                </div>
                <p class="news-brief-introduction">选择一个适合开荒的职业是每位玩家在游戏初期都需要做的决策。不同的职业会影响到你的资源获取速度、队伍发展以及后期战力的强弱。本文就来给大家说说《三国谋定天下》哪个职业适合开荒？</p>
            </div>
        </li> -->
    </ul>
  </div>
</template>

<script>

export default {
    props:{
        newsList:{
            type:Array,
            default:()=>([]),
        }
    },
    data(){
        return {

        }
    },
    methods:{
        //点击文章列表项操作
        clickNewsListItemHandler(item){
            this.$router.push({name:'newsDetails',params:{articleObject:item}})
        }
    },
    mounted(){
        
    }
}
</script>

<style lang="less" scoped>
.news-list-item-content{
    width: 820px;
    ul{
        list-style-type: none;
        li{
            margin: 30px 0px;
            width: 820px;
            height: 188px;
            border-bottom: 1px solid #F0F0F0;
            list-style: none;
            display: flex;
            justify-content: space-between;
            cursor: default;
            .news-list-title-pic{
                width: 280px;
                height: 158px;
            }
            .news-list-title-right{
                width: 520px;
                height: 158px;
                display: flex;
                flex-direction: column;

                .news-title-text{
                    margin-top: 13px;
                    height: 18px;
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #131313;
                    line-height: 18px;
                    text-align: left;
                    font-style: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .news-time-and-page-views{
                    margin-top: 12px;
                    display: flex;
                    align-items: center;
                    .news-time-text{
                        height: 19px;
                        font-family: MicrosoftYaHei;
                        font-size: 14px;
                        color: #666666;
                        line-height: 19px;
                        text-align: left;
                        font-style: normal;
                    }
                    .page-views-icon{
                        margin-left: 20px;
                        width: 11px;
                        height: 8px;
                    }
                    .news-page-views{
                        margin-left: 5px;
                        height: 12px;
                        font-family: MicrosoftYaHei;
                        font-size: 12px;
                        color: #666666;
                        line-height: 12px;
                        text-align: left;
                        font-style: normal;
                    }
                }
                
                .news-brief-introduction{
                    margin-top: 17px;
                    width: 520px;
                    height: 66px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #505050;
                    line-height: 22px;
                    text-align: left;
                    font-style: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-clamp: 3;
                    -webkit-line-clamp:3;
                    word-break: break-all;
                }
            }
            &:hover .news-title-text{
                color: #F02233;
            }
        }
    }
}
</style>